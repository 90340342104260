import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../translations/en.json';
import translationPT from '../translations/pt.json';
import translationDE from '../translations/de.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 
    keySeparator: false,
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
