import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAuth : '',
  isAdmin : false,
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.userAuth = action.payload;
    },
    saveAdmin:(state, action) => {
      state.isAdmin = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveUser, saveAdmin } = authSlice.actions;

export const selectUser = (state) => state.auth.userAuth;

export const selectAdmin = (state) => state.auth.isAdmin;

export default authSlice.reducer;