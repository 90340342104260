import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    conversationAuth: '',
};

export const conversationSlice = createSlice({
    name: "conversation",
    initialState,
    reducers: {
        saveConversation: (state, action) => {
            state.conversationAuth = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { saveConversation } = conversationSlice.actions;

export const selectConversationId = (state) => state.conversation.conversationAuth;

export default conversationSlice.reducer;